import React from "react"
import theme from "theme"
import { Theme, Text, Hr, Box, Section, Image } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"about"} />
      <Helmet>
        <title>О нас | Откройте для себя гольф-клуб Caminera Golf</title>
        <meta
          name={"description"}
          content={"Наследие отдыха, будущее веселья!"}
        />
        <meta
          property={"og:title"}
          content={"О нас | Откройте для себя гольф-клуб Caminera Golf"}
        />
        <meta
          property={"og:description"}
          content={"Наследие отдыха, будущее веселья!"}
        />
        <meta
          property={"og:image"}
          content={"https://glimmerixfun.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://glimmerixfun.com/img/56456654.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://glimmerixfun.com/img/56456654.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://glimmerixfun.com/img/56456654.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://glimmerixfun.com/img/56456654.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://glimmerixfun.com/img/56456654.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://glimmerixfun.com/img/56456654.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        background="url(https://glimmerixfun.com/img/4.jpg) center/cover"
        padding="140px 0"
        sm-padding="40px 0"
        quarkly-title="About-16"
      >
        <Override
          slot="SectionContent"
          align-items="center"
          justify-content="center"
          sm-min-width="280px"
        />
        <Box
          padding="50px 80px 50px 50px"
          color="--dark"
          display="flex"
          flex-direction="column"
          align-items="center"
          max-width="670px"
          background="#ffffff"
          sm-padding="50px 24px 50px 24px"
        >
          <Text margin="0 0 16px 0" as="h2" font="--headline2">
            Наша история
          </Text>
          <Hr
            min-height="10px"
            margin="0px 0px 0px 0px"
            border-color="--color-dark"
            border-width="3px 0 0 0"
            max-width="50px"
            width="100%"
          />
          <Text font="--base" text-align="center">
          С момента открытия наших ворот гольф-клуб Caminera Golf посвятил себя созданию исключительных условий для игры в гольф. Созданное на ландшафте, который, казалось, был предназначен для легендарных игр, наше поле стало пристанищем для тех, кто ищет как вызов, так и спокойствие. За годы работы мы создали место, где игроки в гольф могут проверить свои навыки, насладиться отдыхом на природе и отдохнуть от повседневных забот.
          </Text>
        </Box>
      </Section>
      <Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
        <Override
          slot="SectionContent"
          flex-direction="row"
          md-flex-wrap="wrap"
          flex-wrap="wrap"
        />
        <Box
          width="100%"
          margin="0px 0px 64px 0px"
          md-margin="0px 0px 30px 0px"
        >
          <Text
            margin="0px 0px 0px 0px"
            color="--dark"
            font="--headline2"
            width="45%"
            lg-width="100%"
            lg-text-align="center"
          >
            Наше поле
          </Text>
        </Box>
        <Box display="flex" width="100%" md-flex-wrap="wrap">
          <Box
            width="48.5%"
            display="flex"
            flex-direction="column"
            justify-content="space-between"
            md-width="100%"
            padding="0px 0px 0px 0px"
            md-padding="0px 0px 0px 0px"
            md-margin="0px 0px 40px 0px"
            margin="0px 3% 0px 0px"
          >
            <Text
              margin="0px 0px 0px 0px"
              color="--darkL2"
              font="--base"
              md-text-align="center"
            >
              Наше поле для гольфа является свидетельством классического дизайна, воплощенного в современном стиле. На нем представлены разнообразные лунки, каждая из которых создана так, чтобы дополнять естественные очертания земли. Планировка поощряет стратегическую игру, вознаграждая продуманные подходы и искусные удары. От пологих фервеев до утонченно наклонных гринов - Caminera Golf создан для того, чтобы вы никогда не уставали играть.
            </Text>
          </Box>
          <Box
            width="48.5%"
            display="flex"
            flex-direction="column"
            justify-content="space-between"
            md-width="100%"
            padding="0 0px 0 0px"
          >
            <Text
              margin="0px 0px 0px 0px"
              color="--darkL2"
              font="--base"
              md-text-align="center"
            >
              Caminera Golf - это не просто место для игры в гольф, это место для развития, отдыха и общения. Мы верим, что каждая игра - это история, которая только начинается. Независимо от того, хотите ли вы улучшить свой удар, принять участие в турнире или насладиться неспешной игрой, Caminera Golf предлагает фон, который вдохновляет и бросает вызов. Примите дух приключений и сделайте свой след на поле!
            </Text>
          </Box>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-1"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          width="50%"
          align-items="flex-start"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          justify-content="center"
          lg-width="100%"
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
        >
          <Box
            width="100%"
            position="relative"
            transform="translateY(0px)"
            hover-transform="translateY(-10px)"
            padding="0px 0px 100% 0px"
            height="auto"
            overflow-x="hidden"
            overflow-y="hidden"
            transition="transform 0.2s ease-in-out 0s"
          >
            <Image
              src="https://glimmerixfun.com/img/5.jpg"
              position="absolute"
              display="block"
              width="100%"
              left={0}
              min-height="100%"
              object-fit="cover"
              top={0}
              right={0}
              bottom={0}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          width="50%"
          align-items="flex-start"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          lg-order="1"
          flex-direction="column"
          lg-width="100%"
          sm-margin="0px 0px 0px 0px"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
        >
          <Box
            overflow-y="hidden"
            position="relative"
            transform="translateY(0px)"
            hover-transform="translateY(-10px)"
            width="100%"
            height="auto"
            overflow-x="hidden"
            transition="transform 0.2s ease-in-out 0s"
            padding="0px 0px 70% 0px"
          >
            <Image
              bottom={0}
              src="https://glimmerixfun.com/img/6.jpg"
              top={0}
              display="block"
              width="100%"
              left={0}
              right={0}
              min-height="100%"
              object-fit="cover"
              position="absolute"
            />
          </Box>
          <Text
            margin="24px 0px 0px 0px"
            color="--darkL2"
            font="--lead"
            lg-text-align="center"
            md-text-align="left"
          >
           Основные моменты Caminera Golf
          </Text>
          <Text
            margin="5px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            md-text-align="left"
          >
            Пейзажная красота: С каждой лунки открывается вид, на котором стоит остановиться, поэтому каждый раунд - это новая возможность насладиться природной красотой.
            <br />
            <br />
            Инновационное обслуживание: Мы используем новаторские методы ухода за зелеными насаждениями, которые обеспечивают отличные условия для игр круглый год, при этом бережно относясь к окружающей среде.
            <br />
            <br />
            Яркие события сообщества: Наш календарь полон интересных мероприятий, призванных улучшить ваш опыт и укрепить дух сообщества между членами.
          </Text>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
